import React, { useEffect, useState, useCallback } from "react";
import { Table } from "antd";
import { createStyles } from "antd-style";

import { configUpdateAPI, fetchConfigAPI } from "../../../services/targetAPI";

import { DownOutlined, UpOutlined } from "@ant-design/icons";
import PMFeesMonthlyTable from "./PMFeesMonthlyTable";
import TargetMonthlyTable from "./TargetMonthlyTable";

import { PMTableChanges, Property } from "./types";

import { LISTINGS_SETTINGSCOLUMN } from "./constants";

const useStyle = createStyles(({ css }) => ({
  customTable: css`
    .ant-table-row-expand-icon-cell {
      width: 30px !important;
    }
  `,
}));

type MainTableChanges = Record<
  string | number,
  Record<string, string | number>
>;

interface TargetTableChanges {
  [id: string]: {
    [month: string]: string | number | null;
  };
}

interface PmFeesTableChanges {
  [id: string]: {
    [month: string]: string | number | null;
  };
}

const ConfigTable: React.FC<{
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  setConfigureTableOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isEditing, setIsEditing, setConfigureTableOpen }) => {
  const { styles } = useStyle();
  const [configData, setConfigData] = useState<Property[] | []>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSavingLoading, setIsSavingLoading] = useState<boolean>(false);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);

  const [offset, setOffset] = useState<number>(0);
  const [limit] = useState<number>(60);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const [rowEditingStates, setRowEditingStates] = useState<
    Record<number, boolean>
  >({});
  const [mainTableChanges, setMainTableChanges] = useState<MainTableChanges>(
    {}
  );
  const [pmFeesChanges, setPmFeesChanges] = useState<PmFeesTableChanges>({});
  const [targetTableChanges, setTargetTableChanges] = useState<TargetTableChanges>({});

  const fetchConfig = async() => {
    if (!hasMore) return; // Stop fetching if no more data

    try {
      setIsLoading(true);
      const response = await fetchConfigAPI(offset, limit); // Adjusted fetch call
      setConfigData(response || []);
      const newResults = response || [];
      let count = 0;
      setConfigData((prev) => {
        const filteredResults = newResults.filter(
          (newItem: Property) =>
            !prev.some(
              (existingItem) =>
                existingItem.internal_listing_name ===
                  newItem.internal_listing_name &&
                existingItem.id === newItem.id
            )
        );
        count = prev.length + filteredResults?.length;
        return [...prev, ...filteredResults];
      });

      setOffset(count);

      if (newResults.length < limit) {
        setHasMore(false);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePmFeesEdit = (
    id: string | number,
    month: string,
    value: string | number | null
  ): void => {
    setPmFeesChanges((prev) => ({
      ...prev,
      [id]: {
        ...(prev[id] || {}),
        [month]: value,
      },
    }));
  };
  
  const handleTargetEdit = (
    id: string | number,
    month: string,
    value: string | number | null
  ): void => {
    setTargetTableChanges((prev) => ({
      ...prev,
      [id]: {
        ...(prev[id] || {}),
        [month]: value,
      },
    }));
  };
  

  const handleRowEditToggle = async (id: number, editing: boolean) => {
    if (!editing) {
      await handleSave(id);
    } else {
      handleCancel(id);
    }
  
    setRowEditingStates((prev) => ({
      ...prev,
      [id]: editing,
    }));
  };

  const handleSave = async (id: number) => {
    setIsSavingLoading(true);
  
    const payload = {
      mainTable: { [id]: mainTableChanges[id] || {} },
      pmFees: { [id]: pmFeesChanges[id] || {} },
      targetTable: { [id]: targetTableChanges[id] || {} },
    };
  
    try {
      // Save changes for the specific row
      await configUpdateAPI({ payload });
  
      // Clear changes for the saved row
      setMainTableChanges((prev) => {
        const updated = { ...prev };
        delete updated[id];
        return updated;
      });
  
      setPmFeesChanges((prev) => {
        const updated = { ...prev };
        delete updated[id];
        return updated;
      });
  
      setTargetTableChanges((prev) => {
        const updated = { ...prev };
        delete updated[id];
        return updated;
      });
  
      // Exit editing mode for the saved row
      setRowEditingStates((prev) => ({
        ...prev,
        [id]: false,
      }));
  
      // Optionally refresh the data for the saved row
      setHasMore(true);
      setOffset(0);
      await fetchConfig();
    } catch (err) {
      console.error("Error saving changes for row:", err);
    } finally {
      setIsSavingLoading(false);
    }
  };
  
  

  const handleCancel = (id: number) => {
    // Remove unsaved changes for the specific row
    setMainTableChanges((prev) => {
      const updated = { ...prev };
      delete updated[id];
      return updated;
    });
    setPmFeesChanges((prev) => {
      const updated = { ...prev };
      delete updated[id];
      return updated;
    });
    setTargetTableChanges((prev) => {
      const updated = { ...prev };
      delete updated[id];
      return updated;
    });
  
    // Exit editing mode
    setRowEditingStates((prev) => ({
      ...prev,
      [id]: false,
    }));
  };

  const expandedRowRender = (record: Property) => {
    const isExpanded = expandedKeys.includes(record?.id);
    return (
      <>
        <div
          style={{ padding: "10px 20px" }}
          className="shadow-lg bg-white rounded-lg"
        >
          <PMFeesMonthlyTable
            propertyID={record?.id}
            standardPMFee={record?.pm_fees}
            isEditing={rowEditingStates[record.id] || false}
            isExpanded={isExpanded}
            pmFeesChanges={pmFeesChanges[record?.id] || {}}
            handlePmFeesEdit={handlePmFeesEdit}
          />
        </div>
        <div
          style={{ padding: "10px 20px" }}
          className="shadow-lg bg-white rounded-lg mt-3"
        >
          <TargetMonthlyTable
          propertyID={record?.id}
          isEditing={rowEditingStates[record.id] || false}
          handleTargetEdit={handleTargetEdit}
          isExpanded={isExpanded}
          targetTableChanges={targetTableChanges[record?.id] || {}}
        />
        </div>
      </>
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      const { scrollHeight, scrollTop, clientHeight } =
        document.documentElement;

      if (scrollHeight - scrollTop - clientHeight <= 100) {
        fetchConfig();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [fetchConfig, offset]);

  useEffect(() => {
    if (offset === 0) {
      fetchConfig();
    }
  }, [fetchConfig]);


  return (
    <>
      <div style={{ position: "relative" }}>
        <div style={{ overflowX: "auto" }}>
          <Table
            columns={LISTINGS_SETTINGSCOLUMN(
              rowEditingStates,
              handleRowEditToggle,
              handleCancel,
              (id: string | number, field: string, value: string | number) =>
                setMainTableChanges((prev: MainTableChanges) => ({
                  ...prev,
                  [id]: {
                    ...(prev[id] || {}),
                    [field]: value,
                  },
                })),
              isSavingLoading
            )}
            dataSource={configData}
            rowKey="id"
            bordered
            className={`font-semibold ${styles.customTable}`}
            pagination={false}
            loading={isLoading}
            expandable={{
              expandedRowRender,
              expandRowByClick: false,
              expandedRowKeys: expandedKeys,
              expandIconColumnIndex: 0,
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <UpOutlined
                    onClick={(e) => {
                      onExpand(record, e);
                    }}
                  />
                ) : (
                  <DownOutlined onClick={(e) => onExpand(record, e)} />
                ),
              onExpand: (expanded, record) => {
                setExpandedKeys((prev) =>
                  expanded
                    ? [...prev, record.id]
                    : prev.filter((id) => id !== record.id)
                );
              },
            }}
          />
        </div>
        <style>
          {`
          .ant-table-row-expand-icon-cell {
            max-width: 10px !important; /* Adjust this value to make it narrower */
          }

          .ant-table-cell {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        `}
        </style>
      </div>
    </>
  );
};

export default ConfigTable;
