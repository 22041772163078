import { Routes, Route } from "react-router-dom";
import Home from "./home";
import Bookings from "./bookings";
import Targets from "./portfolio";
import Listings from "./listings";
import Performance from "./performance"

const DashboardRoutes = () => {
  return (
    <Routes>
      <Route path="/home" element={<Home />} />
      <Route path="/bookings" element={<Bookings />} />
      <Route path="/reports/targets" element={<Targets />} />
      <Route path="/reports/channelPerformance" element={<Targets />} />
      <Route path="/listings" element={<Listings />} />
      <Route path="/performance" element={<Performance />} />
      <Route path="*" element={<Home />} /> {/* Fallback or a 404 component */}
    </Routes>
  );
};

export default DashboardRoutes;
