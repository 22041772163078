import React, { useEffect, useState } from "react";
import { InputNumber, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Typography } from "@mui/material";
import { FileExcelOutlined } from "@ant-design/icons";
import MonthPicker from "../../../components/MonthPicker";
import dayjs, { Dayjs } from "dayjs";
import { customPMFeesTable } from "../../../services/targetAPI";
import { MONTHS } from "./constants";
import ExcelTransformModal from "./ExcelTranformModal";
import { MonthlyData } from "./types";

const PMFeesMonthlyTable: React.FC<{
  propertyID: string | number;
  isEditing: boolean;
  isExpanded: boolean;
  handlePmFeesEdit: (
    id: string | number,
    month: string,
    value: string | number | null
  ) => void;
  standardPMFee: string | number;
  pmFeesChanges: any;
}> = ({
  propertyID,
  isEditing,
  handlePmFeesEdit,
  isExpanded,
  pmFeesChanges,
}) => {
  const currentYear = dayjs().year();

  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    dayjs().year(currentYear)
  );
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editedData, setEditedData] = useState<Record<string, number> | null>(
    {}
  );

  const fetchCustomPMFees = async () => {
    setIsLoading(true);
    let startYear = dayjs().year(currentYear).startOf("year").format("YYYY-MM");
    let endYear = dayjs().year(currentYear).endOf("year").format("YYYY-MM");
    if (selectedDate) {
      startYear = selectedDate.startOf("year").format("YYYY-MM");
      endYear = selectedDate.endOf("year").format("YYYY-MM");
    }
    try {
      const response = await customPMFeesTable({
        propertyID,
        params: {
          start_month: startYear,
          end_month: endYear,
        },
      });

      const updatedData = response?.data.map((item: any) => {
        if (pmFeesChanges) {
          const updates = pmFeesChanges;

          const updatedPropertyData = { ...item };

          Object.keys(updates).forEach((key) => {
            const [month, year] = key.split("-");
            if (parseInt(year) === selectedDate?.year()) {
              updatedPropertyData[month] = parseFloat(updates[key]);
            }
          });

          return {
            ...item,
            ...updatedPropertyData,
          };
        }

        return item;
      });
      
      setData(response?.data || []);
      setEditedData(updatedData[0] || {});
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (month: string, value: number | null) => {
    setEditedData((prev: any) => ({
      ...prev,
      [month]: value,
    }));
    handlePmFeesEdit(propertyID, `${month}-${selectedDate?.year()}`, value);
  };

  const handleApplyChanges = (updatedData: Record<string, number> | null) => {
    setEditedData(updatedData);
    updatedData &&
      Object.keys(updatedData).forEach((month) => {
        handlePmFeesEdit(
          propertyID,
          `${month}-${selectedDate?.year()}`,
          updatedData[month]
        );
      });
    setIsModalVisible(false);
  };

  useEffect(() => {
    isExpanded && selectedDate && fetchCustomPMFees();
  }, [selectedDate, isExpanded, isEditing]);

  const columns: ColumnsType<MonthlyData> = MONTHS.map((month: any) => ({
    title: month,
    dataIndex: month,
    key: month,
    width: 100,
    align: "center",
    render: (text: any) =>
      isEditing ? (
        <InputNumber
          controls={false}
          value={
            editedData && editedData[month] !== undefined
              ? editedData[month]
              : null
          }
          style={{ width: "60px" }}
          min={0}
          max={100}
          onKeyPress={(e) => {
            // Prevent alphabetic characters
            if (!/[0-9]/.test(e.key)) {
              e.preventDefault();
            }
          }}
          onChange={(value) => handleInputChange(month, value)}
        />
      ) : (
        text
      ),
  }));

  return (
    <div>
      <div className="w-full flex flex-cols-2 justify-between pb-4 items-center">
        <Typography variant="subtitle2" className="!text-black ">
          PM Fees (%)
        </Typography>
        <div className="!w-fit flex items-center gap-4">
          <MonthPicker
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            isMonthly={false}
            allowClear={false}
          />
          {isEditing ? (
            <FileExcelOutlined
              style={{ fontSize: "20px", cursor: "pointer", color: "#28a745" }}
              onClick={() => setIsModalVisible(true)}
            />
          ) : null}
        </div>
      </div>

      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        bordered
        pagination={false}
        className="border-t-2 p-0"
        scroll={{ x: "calc(980px)" }}
        loading={isLoading}
      />

      <ExcelTransformModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        handleApplyChanges={handleApplyChanges}
        initialData={editedData}
      />
      <style>
        {`
          .ant-input-number-input{
            padding-right: 0!important;
            padding-left: 0!important;
            text-align:center!important;
          }
        `}
      </style>
    </div>
  );
};

export default PMFeesMonthlyTable;
