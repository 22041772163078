import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import { MONTHS } from "./constants";

interface ExcelTransformModalType {
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleApplyChanges: (updatedData: Record<string, number> | null) => void;
  initialData: Record<string, number> | null;
}

const ExcelTransformModal = ({
  isModalVisible,
  setIsModalVisible,
  handleApplyChanges,
  initialData,
}: ExcelTransformModalType) => {
  const [matrixData, setMatrixData] = useState<Record<string, number> | null>(
    initialData
  );
  const [selectedRange, setSelectedRange] = useState<{
    start: number;
    end: number;
  } | null>(null);
  const [clickCount, setClickCount] = useState<number>(0);

  useEffect(() => {
    setMatrixData(initialData);
    setClickCount(0);
    setSelectedRange(null);
  }, [isModalVisible, initialData]);

  const handleMouseDown = (index: number) => {
    if (clickCount === 0 || clickCount === 2) {
      setSelectedRange({ start: index, end: index });
      setClickCount(1);
    } else if (clickCount === 1) {
      setSelectedRange((prev) => ({ ...prev!, end: index }));
      setClickCount(2);
    }
  };

  const handleMouseOver = (index: number) => {
    if (clickCount === 1 && selectedRange) {
      setSelectedRange((prev) => ({ ...prev!, end: index }));
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    if (selectedRange) {
      const pasteData = event.clipboardData
        .getData("text")
        .split("\t")
        .map((d: string) => {
          const num = parseFloat(d.toString().replace(",", ""));
          if (isNaN(num)) {
            return 0;
          } else {
            return num;
          }
        });
      const updatedData = { ...matrixData };
      const { start, end } = selectedRange;

      let pasteIndex = 0;
      for (let i = Math.min(start, end); i <= Math.max(start, end); i++) {
        if (pasteIndex < pasteData.length) {
          updatedData[MONTHS[i]] = pasteData[pasteIndex];
          pasteIndex++;
        }
      }

      setMatrixData(updatedData);
    }
  };
  const renderCell = (month: string, index: number) => {
    const isSelected =
      selectedRange &&
      index >= Math.min(selectedRange.start, selectedRange.end) &&
      index <= Math.max(selectedRange.start, selectedRange.end);

    return (
      <div className="flex w-full flex-col space-y-2">
        <div
          key={month}
          className="h-10 border flex items-center justify-center font-bold bg-gray-100 px-2"
        >
          {month}
        </div>
        <div
          key={month}
          className={`h-10 border flex items-center justify-center cursor-pointer px-2 ${
            isSelected ? "bg-blue-100" : "bg-white"
          }`}
          onMouseDown={() => handleMouseDown(index)}
          onMouseOver={() => handleMouseOver(index)}
        >
          {matrixData && matrixData[month] !== undefined
            ? matrixData[month]
            : ""}
        </div>
      </div>
    );
  };

  return (
    <Modal
      title="Paste Excel Data"
      open={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      width={"1200px"}
      footer={[
        <Button key="cancel" onClick={() => setIsModalVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="apply"
          type="primary"
          onClick={() => handleApplyChanges(matrixData)}
        >
          Apply Changes
        </Button>,
      ]}
    >
      <div className="flex flex-col w-full space-y-2 overflow-x-auto" onPaste={handlePaste}>
        <div className="flex w-full">
          {MONTHS.map((month, index) => renderCell(month, index))}
        </div>
      </div>
    </Modal>
  );
};

export default ExcelTransformModal;
