import React, { useCallback, useEffect, useState } from "react";
import { Table, Switch, InputNumber } from "antd";
import { LISTINGS_PROPERTYTABLE } from "./constants";
import { Property_Listing, TargetTableProps } from "./types";
import {
  Button,
  IconButton,
  Drawer,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
} from "@mui/material";
import { createStyles } from "antd-style";

import CloseIcon from "@mui/icons-material/Close";
import TargetSettings from "./ListingsSettings";

import { update } from "../../../services/targetAPI";
import { fetch } from "../../../services/targetAPI";

import { ITEMS_PER_PAGE } from "../utils";
import useSearchPagination from "../../../hooks/useSearchPaginationFetch";

import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import useDebounce from "../../../hooks/useDebounce";
import ReusablePagination from "../../../components/PaginationComponent";
dayjs.extend(isSameOrBefore);

// Custom styling for the table
const useStyle = createStyles(({ css }) => ({
  customTable: css`
    antd-table {
      antd-table-container {
        antd-table-body,
        antd-table-content {
          scrollbar-width: thin;
          scrollbar-color: unset;
        }
      }
    }
  `,
}));

const TargetTable: React.FC<TargetTableProps> = ({
  activeSelection,
  searchValue,
}) => {
  const [monthValues, setMonthValues] = useState<{ [key: string]: number }>({});
  const [pmFeeMonthValues, setPMFeeMonthValues] = useState<{
    [key: string]: number;
  }>({});
  const [pmsFees, setPmsFees] = useState<string | string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [selectedRowID, setSelectedRowID] = useState<string | number | null>(
    null
  );
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
  const [selectedRowName, setSelectedRowName] = useState<string | null>(null);
  const [revenueSelection, setRevenueSelection] = useState<string>("");

  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

  const [isDisabled, setIsDisabled] = useState<
    Record<number | string, boolean>
  >({});

  const [tempIsDisabled, setTempIsDisabled] = useState<boolean | null>(null);

  const {
    data,
    setData,
    currentPageNumber,
    setCurrentPageNumber,
    totalItems,
    isLoading,
    currentPageSize,
    setCurrentPageSize,
    setTotalItems,
    setIsLoading,
  } = useSearchPagination("", ITEMS_PER_PAGE);

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const fetchTargets = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        currentPageNumber,
        currentPageSize,
        activeSelection,
        debouncedSearchValue
      );
      const results = response?.data?.results || [];
      setData(results);

      const updatedIsDisabled: Record<number | string, boolean> = {};
    results.forEach((item: { id: string | number; is_disabled: boolean; }) => {
      updatedIsDisabled[item.id] = item.is_disabled || false; // Update based on your API response key
    });
    setIsDisabled(updatedIsDisabled);

      setTotalItems(response?.data?.count);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [
    currentPageNumber,
    currentPageSize,
    activeSelection,
    debouncedSearchValue,
  ]);

  useEffect(() => {
    fetchTargets();
  }, [fetchTargets]);

  const { styles } = useStyle();

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    record: Property_Listing
  ) => {
    setPmsFees(record.pm_fees || "");
    console.log(record, "record");
    setStartDate(record.start_date || "");
    setEndDate(record.end_date || "");
    if (record.is_pm_fees_from_net === true) {
      setRevenueSelection("Net");
    } else {
      setRevenueSelection("Gross");
    }

    setSettingsOpen(true);
    setSelectedRowID(record?.id);
    setSelectedRowName(record?.internal_listing_name || "");

    setTempIsDisabled(isDisabled[record.id] ?? false);
  };

  const handleValueChange = (month: string, value: number) => {
    setMonthValues((prev) => ({ ...prev, [month]: value }));
  };

  const handlePMFeesValueChange = (month: string, value: number) => {
    setPMFeeMonthValues((prev) => ({ ...prev, [month]: value }));
  };

  const handleStartDateChange = (e: { target: { value: any } }) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);

    if (endDate && newStartDate > endDate) {
      setErrorMessage("Start date cannot be later than the end date.");
    } else {
      setErrorMessage("");
    }
  };

  const handleEndDateChange = (e: { target: { value: any } }) => {
    const newEndDate = e.target.value;
    setEndDate(newEndDate);

    if (!newEndDate) {
      setErrorMessage("");
    } else if (startDate > newEndDate) {
      setErrorMessage("End date cannot be earlier than the start date.");
    } else {
      setErrorMessage("");
    }
  };

  const handleClose = () => {
    setSettingsOpen(false);
    setTempIsDisabled(null);
  };

  const handleToggleChange = (id: number | string, checked: boolean) => {
    setIsDisabled((prev) => ({
      ...prev,
      [id]: !checked, // Update the toggle state for this specific row
    }));
  };

  const handleSave = async () => {
    if (pmsFees && !revenueSelection) {
      setError(true);
      return;
    }
    console.log(
      monthValues,
      pmFeeMonthValues,
      pmsFees,
      startDate,
      endDate,
      revenueSelection,
      "logssss"
    );
    setError(false);
    if (selectedRowID !== null && tempIsDisabled !== null) {
      setIsSaving(true);
      try {
        const response = await update({
          propertyID: selectedRowID,
          payload: {
            monthValues,
            pmFeeMonthValues,
            pmsFees,
            startDate,
            endDate,
            revenueSelection,
            isDisabled: tempIsDisabled,
          },
        });
        setIsDisabled((prev) => ({
          ...prev,
          [selectedRowID]: tempIsDisabled,
        }));
        handleClose();
        fetchTargets();
      } catch (error) {
        console.error("Error saving property targets or pm fees:", error);
      } finally {
        setIsSaving(false);
      }
    } else {
      console.error("Property ID is not defined");
    }
  };

  const columns = LISTINGS_PROPERTYTABLE(handleMenuClick);

  useEffect(() => {
    if (isInitialLoad) {
      fetchTargets();
      setIsInitialLoad(false);
    }
  }, [fetchTargets, isInitialLoad]);

  useEffect(() => {
    if (!isInitialLoad) {
      setData([]);
      setCurrentPageNumber(1);
      currentPageNumber === 1 && fetchTargets();
    }
  }, [currentPageSize, activeSelection, debouncedSearchValue]);

  useEffect(() => {
    if (!isInitialLoad) {
      setData([]);
      fetchTargets();
    }
  }, [currentPageNumber]);

  useEffect(() => {
    if (isInitialLoad) {
      fetchTargets();
      setIsInitialLoad(false);
    }
  }, [fetchTargets, isInitialLoad]);

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        bordered
        className={`font-semibold ${styles.customTable}`}
        pagination={false}
        loading={isLoading}
      />

      {/* Drawer or Modal for showing inner page content */}
      <Drawer
        anchor="right"
        open={settingsOpen}
        onClose={handleClose}
        PaperProps={{ style: { width: "45%", overflowY: "auto" } }}
        className="ml-auto !shadow-none"
      >
        <div className=" py-3 w-full !min-h-fit space-y-6 !shadow-none !bg-white px-5">
          <div className="flex inline-flex justify-between w-full">
            <Typography variant="h4" className="!text-gray-500">
              Settings
            </Typography>

            <div className="flex w-full justify-end">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div>
            {selectedRowName !== "" ? (
              <div className="flex inline-flex justify-between w-full">
                <div className="flex justify-start gap-4 w-full items-center">
                  <Typography>Unit:</Typography> {selectedRowName}
                </div>
                <div>
                  <Switch
                    // checked={!isDisabledMap[selectedRowID || ""]}
                      // onChange={(checked) =>
                      //   handleToggleChange(selectedRowID || "", checked)
                      // }
                      checked={tempIsDisabled !== null ? !tempIsDisabled : !isDisabled[selectedRowID || ""]}
                      onChange={(checked) => setTempIsDisabled(!checked)}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="bg-gray-100 px-5 py-3 shadow-lg">
            <div className="flex inline-flex w-full justify-start items-center flex-cols-2 pb-6">
              <div className="w-1/2">
                <Typography>Standard PM Fees</Typography>
                <InputNumber
                  variant="outlined"
                  className="bg-white rounded-lg shadow-lg w-4/5 h-14 outline-none border-none items-center"
                  max={"100"}
                  value={pmsFees}
                  onChange={(value) => setPmsFees(value !== null ? value.toString() : "")} 
                  addonAfter="%"
                  onKeyPress={(e) => {
                    // Prevent alphabetic characters
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <div className="w-1/2 !justify-start h-full pl-3">
                <Typography>Commission on Net / Gross revenue</Typography>
                <RadioGroup
                  row
                  value={revenueSelection}
                  onChange={(e) => {
                    setRevenueSelection(e.target.value);
                    setError(false);
                  }}
                  className="justify-start mr-5 w-4/5"
                >
                  <FormControlLabel
                    value="Net"
                    control={<Radio color="primary" />}
                    label="Net"
                  />
                  <FormControlLabel
                    value="Gross"
                    control={<Radio color="primary" />}
                    label="Gross"
                  />
                </RadioGroup>
                {error && (
                  <p style={{ color: "red", marginTop: "4px" }}>
                    Please select any one.
                  </p>
                )}
              </div>
            </div>
            <div className="flex space-x-2 w-full  flex-cols-2 pb-3">
              {/* Start Date Field */}
              <div className="w-1/2">
                <TextField
                  label="Start Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  className="bg-white w-4/5"
                  value={startDate}
                  onChange={handleStartDateChange}
                />
              </div>

              {/* End Date Field */}
              <div className="w-1/2 text-start">
                <TextField
                  label="End Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  className="bg-white w-4/5"
                  value={endDate}
                  error={errorMessage?.length > 0}
                  helperText={errorMessage?.length > 0 ? errorMessage : ""}
                  onChange={handleEndDateChange}
                />
              </div>
            </div>
          </div>
          <TargetSettings
            monthValues={monthValues}
            handleValueChange={handleValueChange}
            handlePMFeesValueChange={handlePMFeesValueChange}
            propertyID={selectedRowID}
            setMonthValues={setMonthValues}
            selectedRowName={selectedRowName}
            setPMFeeMonthValues={setPMFeeMonthValues}
            pmFeeMonthValues={pmFeeMonthValues}
          />
          <div className="flex flex-inline justify-between w-full px-6 py-2 align-right">
            <Button
              onClick={handleClose}
              variant="outlined"
              className="py-2 px-3 rounded-lg !font-medium !bg-gray-500 !text-white"
            >
              Close
            </Button>
            <Button
              className="p-2 rounded-lg !font-medium"
              type="button"
              variant="contained"
              disabled={errorMessage?.length > 0 || error || isSaving}
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </div>
      </Drawer>

      <ReusablePagination
        currentPage={currentPageNumber}
        onPageSizeChange={setCurrentPageSize}
        pageSize={currentPageSize}
        totalItems={totalItems}
        onPageChange={setCurrentPageNumber}
      />
      <style>
        {`
          .ant-input-number-group{
            border:none!important;
            min-height: 100%!important;
            items-align: center!important;
          }
          .ant-input-number-input{
            min-height: 100%!important;
            height: 55px;
          }
        `}
      </style>
    </>
  );
};

export default TargetTable;
