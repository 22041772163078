import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

const HomeSkeleton: React.FC = () => {
  return (
    <Box className="grid w-full grid-cols-5 gap-0 py-6 bg-gray-100" >
      <Skeleton className="min-h-48" variant="rectangular" />
      <Skeleton className="min-h-48" variant="rectangular" />
      <Skeleton className="min-h-48" variant="rectangular" />
      <Skeleton className="min-h-48" variant="rectangular" />
      <Skeleton className="min-h-48" variant="rectangular" />
    </Box>
  );
};

export default HomeSkeleton;
